import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { BrowserRouter as Router, Switch, useRouteMatch, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header-sidebar/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import HeaderSidebar from './shared/layout/header-sidebar/header-sidebar';
import { Detail } from './modules/detail/detail';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {
}

export const App = (props: IAppProps) => {


  useEffect(() => {
    props.getSession();
  }, []);

  const styles = {
    styleLogin: {
      backgroundImage: `url('content/images/background-login.png')`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh'
    },
    styles: {
      paddingTop: '20px'
    }
  };
  return (
    <Router basename={baseHref}>
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
    </Router>
  );
};

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
});

const mapDispatchToProps = { setLocale, getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
