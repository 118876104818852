import React, {useState, useEffect, useRef} from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import PinField from "react-pin-field";
import Axios from "axios";
import {toast} from "react-toastify";
import swal from 'sweetalert';
import moment from 'moment'
import ButtonWithLoading from "app/component/button-loading";
import Modal from 'bootstrap/js/dist/modal';
import "app/modules/detail/detail.css"
import {Spinner} from "reactstrap";

export interface ICustomerProps extends StateProps, RouteComponentProps<{ id: any }> {
}

export const Detail2 = (props: ICustomerProps) => {
  const [state, setState] = useState<any>({otp: ""});
  const history = useHistory()
  const [key, setKey] = useState<any>();
  const [tokens, setTokens] = useState<any>();
  const [listHistory, setListHistory] = useState<any>()
  const [loadingOTP, setLoadingOTP] = useState<boolean>(false)
  const [loadingSubmitOTP, setLoadingSubmitOTP] = useState<boolean>(false)
  const [otpKey, setOtpKey] = useState<string>("")

  const handleOtp = (otp) => {
    setState({
      otp
    });
  };

  const getOtp = (event) => {
    Axios.get(`/services/clientservice/api/v1/navi-offline-customers/send-token-string?accountNo=${event?.accountNo}`).then(res =>{
      setLoadingOTP(false)
      setOtpKey(res.data.data.result)
    })
  }

  const historyOrder = (event: any) => {
    Axios.get(`/services/txofflineservice/api/trx-group-orders/${event}`).then(res => {
      setListHistory(res.data)
      getOtp(res.data)
    })
  }
  const tokenVerification = (event: any) => {
    Axios.get(`/services/txofflineservice/api/v1/token/verify?token=${event.data}&validate=false`).then(res => {
      // eslint-disable-next-line no-console
      if (res.data === false) {
        setLoadingOTP(false)
        history.push(`/`, {})
      } else {
        historyOrder(event.id);
      }
    })
  }

  useEffect(() => {
    const keys = location.pathname;
    setKey(keys);
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    setTokens(token);
    const newStr = keys.replace('/', '');
    const real = {data: token, id: newStr};
    setLoadingOTP(true)
    tokenVerification(real);

  }, []);


  const submitOrder = () => {
    swal("Please wait, saving data...", "", "info")
    Axios.get(`/services/clientservice/api/v1/navi-offline-customers/verify-token?token=${state?.otp}&accountNo=${listHistory?.accountNo}&email=${listHistory?.email}&trxGroupOrderid=${listHistory?.id}&jwt=${tokens}`)
      .then(res => {
        if (res.data.data.result) {
          setLoadingSubmitOTP(false)
          Axios.get(`/services/txofflineservice/api/v1/token/verify?token=${tokens}&validate=true`).then(ress => {
            if (ress.data === true) {
              // history.push(`/result`, {})
              window.location.href="/result"
            } else {
              swal("Failed !", "OTP is Invalid", "warning");
            }
          })
        } else {
          swal("Failed !", "OTP is Invalid", "warning");

        }
      })
      .catch(err => {
        swal("Failed !", "Your Transaction Failed Send To NAVI", "warning");
      })
  }

  return (
    <>
      <div className='container'>
          <div style={{height:"100vh"}} className={"row d-flex align-items-center justify-content-center"}>
            <div className="col-lg-6 col-sm-12 col-md-12 p-5">
              <div className={"row d-flex justify-content-center"}>
              <div className={"text-center"}>
                <img src="content/images/logo-navi.png" alt="Logo Mirae Asset" className='m-0 pt-4 pb-4' height={120}/>
              </div>

              <div className={"row"}>
                <div className='col-12 text-center'>

                  <p>
                    <h3>Please Confirm Your Transaction!</h3><br/>
                    Type the verification code you see in the picture below to confirm your transaction
                    <br/>
                    <br/>

                    <div className={"row d-flex justify-content-center"}>
                      {!loadingOTP?<h2 className="boxx" style={{margin: 0}}>{otpKey}</h2> :
                      <h2 className="boxx" style={{margin: 0}}><Spinner color='#fff' size="sm" style={{marginBottom: "2px" }}/></h2>}
                    </div>

                    <br/>

                    Enter verification code

                  </p>

                  <div className="mb-4 text-center">
                    <PinField
                      length={6}
                      onChange={handleOtp}
                      className="mx-1 bg-transparent form-control"
                      validate="0123456789" inputMode="numeric"
                      maxLength={1}
                      style={{
                        width: 32,
                        height: 32,
                        display: 'inline-block',
                        fontSize: 20,
                        textAlign: 'center',
                        padding:0
                      }}
                    />
                  </div>

                  <button style={{width:"100%", height:"36px", padding:"0"}} className="btn btn-lg btn-primary" onClick={() => {
                    submitOrder()
                  }}>{loadingSubmitOTP? <Spinner color='#fff' size="sm" style={{marginRight:"2px"}}/> : <></>} Confirm
                  </button>

                  <div style={{marginTop: "70px"}}>
                    <div className={"row d-flex justify-content-center"}>
                      <img className="img" src="https://navi.miraeasset.co.id/services/clientservice/api/v1/content/img/logo/miraelogo.png"/>
                    </div>
                    <p className="content2">PT. Mirae Asset Sekuritas Indonesia</p>
                    <p className="content2">Registered and supervised by
                      <img
                      src="https://navi.miraeasset.co.id/services/clientservice/api/v1/content/img/logo/logoojk.jpg"
                      style={{border:0, outline:"none", textDecoration:"none", height:"auto"}} width="60"/>
                    </p>
                  </div>

                </div>
              </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Detail2);
