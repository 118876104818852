import React from "react";
import { connect } from "react-redux";

export const HealthCheck = (props) => {

    return (
        <>Up</>
    );

}

export default connect()(HealthCheck);
