import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { Detail } from './modules/detail/detail';
import { Detail2 } from './modules/detail/detail2';
import PrivateRoute from 'app/shared/auth/private-route';
import {Success} from "app/modules/detail/success";
import {HealthCheck} from "app/healthcheck";

const Routes = () => (
  <div className="view-routes">
    <Switch>
      {/*<ErrorBoundaryRoute exact path="/:id" component={Detail} />*/}
      <ErrorBoundaryRoute path="/healthcheck" component={HealthCheck} />
      <ErrorBoundaryRoute path="/result" component={Success} />
      <ErrorBoundaryRoute exact path="/:id" component={Detail2} />
      <ErrorBoundaryRoute component={PageNotFound} />
    </Switch>
  </div>
);

export default Routes;
