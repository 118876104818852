import React from "react";

export const Success = () => {
  return (
    <div className='container'>
      <div style={{height: "100vh"}} className={"row d-flex align-items-center justify-content-center"}>
        <div className="col-lg-6 col-sm-12 col-md-12 p-5">
          <div className={"row d-flex justify-content-center"}>
            <div className={"text-center"}>
              <img src="content/images/logo-navi.png" alt="Logo Mirae Asset" className='m-0 pt-4 pb-4' height={120}/>
            </div>

            <div className={"row"}>
              <div className='col-12 text-center'>

                <p>
                  <h1>Thank You!</h1><br/>
                  <h3>Your transaction has been processed to NAVI</h3>
                  <br/>
                  <br/>

                  <div style={{marginTop: "70px"}}>
                    <div className={"row d-flex justify-content-center"}>
                      <img className="img"
                           src="https://navi.miraeasset.co.id/services/clientservice/api/v1/content/img/logo/miraelogo.png"/>
                    </div>
                    <p className="content2">PT. Mirae Asset Sekuritas Indonesia</p>
                    <p className="content2">Registered and supervised by
                      <img
                        src="https://navi.miraeasset.co.id/services/clientservice/api/v1/content/img/logo/logoojk.jpg"
                        style={{border: 0, outline: "none", textDecoration: "none", height: "auto"}} width="60"/>
                    </p>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success()
